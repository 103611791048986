

import classNames from 'classnames';
function TechStack(){

  const techs = [{name: "REACT.JS", skill: "five"},
                {name: "WORDPRESS", skill: "five"},
                {name: "PHP", skill: "four"},
                {name: "SCSS", skill: "five"},
                {name: "NODE.JS", skill: "five"},
                {name: "MERN STACK", skill: "four"},
                {name: "REDWOOD", skill: "two"},
                {name: "NEXT.JS", skill: "three"},
                {name: ".NET", skill: "two"},
                {name: "PYTHON", skill: "three"},
                {name: "SQL", skill: "three"},
                {name: "JAVA", skill: "three"},
                {name: "C#", skill: "three"},
                {name: "KOTLIN", skill: "three"},
                {name: "REACT NATIVE", skill: "three"},

              ]


const techList = techs.map((work) =>  <span className = {classNames("tech " + work.skill)}> {work.name} </span> );


  return (
    <div className = "tech-wrap">
    <div className = "tech-head">

    </div>
    <div className = "tech-row">
    {techList}

    </div>

    <div className = "score-holder">

    </div>

    </div>
  )
}

export default TechStack;
