

import {motion} from 'framer-motion';
import './quote.css';
function Quote(props){

  const quote = {
    hidden: {
      x: -150,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
    },
  }

  const author = {
    hidden: {
      x: 150,
      opacity: 0,
    },
    show: {
      x: 0,
      transition: {
        delay: 0.3,
      },
      opacity: 1,
    },
  }
  return (
    <div className = "quote-holder">
    <motion.div variants = {quote} initial='hidden'
    whileInView='show'  className= "quote">
    <h3>{props.quote}</h3>
    </motion.div>

    <motion.div variants = {author} initial='hidden'
  whileInView='show' className = "author">
    <h4>{props.author}</h4>
    </motion.div>


    </div>
  )

}

export default Quote;
