import './intro.css';
import {motion} from 'framer-motion';
import TechStack from './TechStack';
function Intro(){



  const svg = {
    hidden: {
      x: 250,
      opacity: 0,
    },
    show: {
      x: 0,
      transition: {
        delay: 0.6,
      },
      opacity: 1,
    }
  }

  const introtitle = {
    hidden: {
      opacity: 0,
      x: -350,
      blur: 10,
      filter: 'blur(10px)',
    },
    show: {
      opacity: 1,
      x: 0,
      filter: 'blur(0px)',
      transition: {
        delay: 0.2,
      }
    }
  }

  const introtext = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
      }
    }

  }


  return (
    <motion.div
    className = "intro"
    >

    <div className = "intro-back"/>
    <div className = "intro-text">
    <motion.div  variants = {introtitle}    viewport={{ once: true }} initial='hidden'
  whileInView='show' className = "intro-header">
    <h1> Hey, my name is <span className = "hl1">Jack.</span> </h1>
    <h1> I Like to <span className = "hl2">create </span> Things. </h1>
    <h1> lets <span className = "hl2">create </span> something  Together </h1>




    </motion.div>

    <motion.p variants = {introtext}    viewport={{ once: true }} initial='hidden'
  whileInView='show'> I'm a university graduate turned freelance developer who has been trying to
    cut out my own little slice of the internet. I believe that every project or problem has a unique
    solution, no cookie cutter templates or methods. A good programmer is not stuck to one tool or methodology, but adapts
    to the clients needs and wants. </motion.p>
    <motion.p variants = {introtext}    viewport={{ once: true }} initial='hidden'
  whileInView='show'>
    I specialise in making solutions with flair and efficency, I love to include animations and interactive elements which draw the eye and
    allow for a unique user experiance. <span className = "hidden-on-mobile"> Below are some of the Technologies I like to use. </span>
    </motion.p>

    <TechStack/>

    </div>



    <div className = "intro-ani"/>



    </motion.div>
  )
}

export default Intro;
