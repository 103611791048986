
import classNames from 'classnames';
import './Works.css';
function Work(props){



  return (
    <div className = {classNames("work " + props.class)}>
    <div className = "overlay">

    <h2  className = "work-title"> {props.name} </h2>
  
    </div>


    </div>
  )

}

export default Work;
