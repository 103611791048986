import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';

import Loader from './components/loader/Loader';
import Landing from './components/landing/Landing';
import Intro from './components/landing/intro/Intro';
import Services from './components/landing/services/Services';
import LandingWorks from './components/landing/works/LandingWorks';
import Quote from './components/general/quote/Quote';
import Divider from './components/general/divider/Divider';
import {motion, AnimatePresence} from 'framer-motion';
import React, {useState, useEffect} from 'react';
function App() {

  const [loading, setLoading] = useState(true);
  return (
    <AnimatePresence className = "gg">
    {loading?
    (
      <motion.div key = "loader">
      <Loader setLoading = {setLoading}/>
      </motion.div>
    ) : ( <>
    <Navbar/>
    <Landing/>

    <Intro/>
    <Quote quote = {"Code is like humor. When you have to explain it, it’s bad."} author = {"Cory House"} />

    <Services/>
    

    <LandingWorks/>


      </>)}

          </AnimatePresence>
  );
}

export default App;
