
import classNames from 'classnames';
import React, {useState, useEffect} from 'react';
function MenuButton(props){

  const [text, setText] = useState([]);

  useEffect(()=> {
      var temp = props.text.split("");
      setText(temp);
      console.log(temp);
  }, []);

  const buttonspans = text.map((char) => <span> {char} </span>);
  return (

    <button className = {classNames("menu-button " + props.class)}>
    <span className = "button-inner">
    {text && text.map((char) => <span> {char} </span>)}

    </span>
    </button>
  
  )
}

export default MenuButton;
