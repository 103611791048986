



import {motion, useMotionValue, useTransform } from 'framer-motion';
import './landing.css';
import Button from '../general/button/Button';

function Landing(){

const x = useMotionValue(200);
 const y = useMotionValue(200);

 const rotateX = useTransform(y, [0, 400], [45, -45]);
 const rotateY = useTransform(x, [0, 400], [-45, 45]);

 function handleMouse(event) {
     const rect = event.currentTarget.getBoundingClientRect();

     x.set(event.clientX - rect.left);
     y.set(event.clientY - rect.top);
 }


  const landingstates = {
  hidden:
  { opacity: 1,
    filter: 'blur(5px)',
    },
  show: {
    opacity: 1,
    filter: 'blur(0px)',
    transition: {

       duration: 0.5,


    }

  }
  }

  const introstates = {
    hidden: {
      border: '0px solid white',
    },
    show: {

    }
  }

  const list = {
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.5,
    },
  },
  hidden: {
    opacity: 1,
    transition: {
      when: "afterChildren",
    },
  },
}

  const line = {
    hidden: {
      scale: 0,
      filter: "blur(20px)",
      y: -50,
      opacity: 1,
    },
    show: {
      opacity: 1,
      x: 0,
      y: 0,
      scale: 1,
      filter: "blur(0px)",
      transition: {delay: 0.75, type: "Spring"},

    }
  }

  const line3 = {
    hidden: {
      opacity: 1,
        filter: "blur(10px)",
      x: -2050,
    },
    show: {
        filter: "blur(0px)",
      opacity: 1,
      x: 0,
      y: 0,
    }
  }

  const button = {
    hidden: {
      y: 150,
      opacity: 0,
      filter: 'blur(10px)',
      rotate: -45,

    },
    show: {
      y: 0,
      opacity: 1,
      rotate: -45,
      filter: 'blur(0px)',
      transition: {
        delay: 1.2,
        bounce: 0.3, duration: 0.5,
        type: 'spring',
      }


    }
  }

  return (

    <div className = "landing">
    <motion.div className = "landing-inner" variants = {landingstates} initial = "hidden" animate = "show">



    <motion.h1 className = "landing-title" variants = {line}>
      <motion.span  className = "line1">Clean <span className = "highlight2">& </span> responsive</motion.span>
      <motion.span className = "line2">full stack</motion.span>
      <motion.span className ="line3">Development.</motion.span>
    </motion.h1>

      <Button hidden = "Good Choice" class = "button" shown = "Get in Touch"/>
      <motion.button variants = {button} initial = "hidden" animate = "show"  className = "down-arrow3">
      <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
 <path d="m1012.1 60-181.57 181.57v111.23l-617.7 617.7-36.625-96.574-105.54 241.73-10.633 24.348 24.457-10.68 241.6-105.49-96.551-36.637 617.7-617.72h111.23l181.57-181.57h-127.86zm-0.8125 128.73m-22.754-71.781 0.046875 77.809-134.45 134.43v-77.855zm-820.07 914.6m-47.535 27.926 53.734-123.05 19.055 50.258z" fill="#fff"/>
</svg>

      </motion.button>
    </motion.div>


    </div>







  )


}

export default Landing;
