
import Work from './Work';

function LandingWorks(){


  const works = [{name: "MMALDN", class: "mma",finsihed: false},
                 {name: "Wigs Works", class: "wig", finished: false},
                 {name: "DRUM! DRUM! DRUM!", class: "drum",  finished: true},
                 {name: "ABC Taxis", class: "abc", finished: true},
                 {name: "Jarolds", class: "ja", finished: true},
                 {name: "WM Agri",  class: "wm", finished: true},


      ]


  const worksList = works.map((work) =>  <Work name = {work.name} class = {work.class} finished = {work.finished}/>);
  return (
    <div className = "section">
    <div className = "section-header">

    <h1>Take a look at my stuff, tell me what you think</h1>
        </div>
    <div className = "works-container">

    </div>



    </div>
  )

}

export default LandingWorks;
