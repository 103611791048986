
import './navbar.css';
import MenuButton from './MenuButton';
import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {motion, useViewportScroll} from 'framer-motion';

function Navbar(props){


  const { scrollYProgress } = useViewportScroll();
  const [logoR, setLogoR] = useState(0);
  const [offTop, setOffTop] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const logostates ={
    hidden: {
      opacity: 0,
      scale: 0,
      y: -100,
    },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,

      transition:{delay: 0.2, duration: 0.5 },
    },

  }

  const logo2states = {
    show: {
      rotate: logoR,
    },
  }

  function SetLogoRotate(){
    setLogoR(scrollYProgress.current * 360);

    if (window.pageYOffset > 60) {
      setOffTop(true);
    }
    else {
      setOffTop(false);
    }
  }


  useEffect(() => {
    const onScroll = e => {
      SetLogoRotate();
    };
    if (window.pageYOffset > 60) {
      setOffTop(true);
    }
    else {
      setOffTop(false);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [logoR]);


  return (

    <header className = {classNames("header", {offTop: offTop})}>

    <motion.div
    variants = {logostates}
    initial = "hidden"
    animate = "show"
    className = "logo"
    onHoverStart= "hoverstart"
    whileHover = "hover"
    onHoverEnd= "show"

    >
    <motion.svg  className = "logo-small" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <motion.g variants = {logo2states} initial = "show" animate = "show" >
    <path d="m600 972c98.66 0 193.28-39.191 263.04-108.96 69.766-69.762 108.96-164.38 108.96-263.04s-39.191-193.28-108.96-263.04c-69.762-69.766-164.38-108.96-263.04-108.96s-193.28 39.191-263.04 108.96c-69.766 69.762-108.96 164.38-108.96 263.04s39.191 193.28 108.96 263.04c69.762 69.766 164.38 108.96 263.04 108.96zm0-72c-21.711-0.015625-43.359-2.3906-64.559-7.0781 45.293-19.531 82.043-54.715 103.53-99.113 21.484-44.402 26.266-95.051 13.473-142.69l-11.16-41.52 30.719-30.48c32.363-32.312 78.988-45.855 123.62-35.91 44.641 9.9414 81.105 41.996 96.695 84.988 1.8711 5.3359 3.3945 10.789 4.5586 16.32-10.668 71.09-46.508 135.98-100.99 182.88-54.488 46.887-124 72.652-195.89 72.605zm284.76-394.32c-39.59-28-87.809-41.094-136.12-36.965-48.316 4.125-93.613 25.211-127.88 59.523l-30.363 30.48-41.52-11.16c-31.223-8.0977-58.445-27.262-76.598-53.926-18.152-26.668-26-59.02-22.086-91.039 3.9141-32.02 19.324-61.527 43.363-83.035 50.59-19.195 105.43-24.352 158.71-14.922 53.281 9.4297 103.02 33.094 143.95 68.488 40.93 35.391 71.523 81.191 88.547 132.55zm-505.68-108h-0.003906c-5.1094 48.633 7.3125 97.492 35.035 137.78 27.723 40.281 68.918 69.344 116.17 81.941l41.52 11.16 11.16 41.52c8.4883 31.723 4.9961 65.461-9.8164 94.77-14.809 29.309-39.898 52.137-70.473 64.117-30.574 11.98-64.492 12.277-95.273 0.83594-41.371-34.801-72.57-80.129-90.301-131.2-17.73-51.07-21.328-105.98-10.418-158.93 10.91-52.949 35.926-101.96 72.398-141.87z" />
    </motion.g>
  </motion.svg>

    <span className = "logo-text">
    JPatten
    </span>
    </motion.div>



    <button onClick = {()=> setHamburger(!hamburger)} className = {classNames("hamburger", {active:hamburger})}>
    <span/>
    <span/>
    <span/>
    </button>

    <div className =  {classNames("menu", {active: hamburger })}>
    <div className = {classNames("menu-back")}/> 
    <MenuButton class = "" text = "about"/>
    <MenuButton class = "" text = "works"/>
    <MenuButton class = "" text = "blog"/>
    <MenuButton class = "contact" text = "contact"/>

    </div>


    </header>




  )
}

export default Navbar;
