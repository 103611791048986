


import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

import {motion} from 'framer-motion';
function Button(props){

  const [standard, setStandard] = useState(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [clicked, setClicked] = useState(false);
    useEffect(() => {
      setStandard(props.shown.split(""));

    }, []);

    function Activate(e){
      var j =  e.clientX - e.target.offsetLeft;
      var v =  e.clientY - e.target.offsetTop;
      setTop(v);
      setLeft(j);

      if (!clicked){
      setClicked(true);
      const s = setTimeout(() => {
      setClicked(false);
    }, 500);

     return () => clearTimeout(s);
    }

    props.callback();

    }

    const button = {
      hidden: {
        y: 150,
        opacity: 0,
        filter: 'blur(10px)',

      },
      show: {
        y: 0,
        opacity: 1,
        filter: 'blur(0px)',
        transition: {
          delay: 0.8,
          bounce: 1, duration: 0.1,
          type: 'spring',
        }


      }
    }

    return(
      <>
      <motion.button variants = {button} initial = "hidden" animate = "show" onClick = {(e)=> Activate(e) } className = {classNames(props.class, {clicked: clicked })}>
      {standard && standard.map((s) =>  <span className = "shown">{s}</span>)}
      <span className = "hidden">
      {props.hidden}


      </span>
      <div style = {{top: top, left: left}} className = "button-effect">

      </div>

      </motion.button>

      </>


    )



}

export default Button;
